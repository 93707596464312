import { AppProps } from "next/app";
import "../styles/wp.css";
import "../styles/index.css";
import "../styles/globals.scss";
import { DialogProvider } from "../context/DialogContext";
import { Toaster } from "react-hot-toast";
import Script from "next/script";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <DialogProvider>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
        `}
      </Script>
      <div>
        <Toaster />
      </div>
      <Component {...pageProps} />
    </DialogProvider>
  );
}

export default MyApp;
